.main-navbar {
    position: fixed;
    background-color: #009bed; /* Choose a background color that fits your design */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    top: 0;
    z-index: 10000;
}
  
.main-navbar .navbar-links a {
    color: white;
    margin-left: 20px;
    text-decoration: none;
    /* Additional styling */
}
  
.powered-by-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    flex-shrink: 0;
    box-sizing: border-box;
}
  
.powered-by-text {
    margin-right: 8px; /* Adjust the spacing as needed */
    color: #ffffff; /* Choose a color that fits the navbar */
    font-size: 0.8em; /* Adjust the font size as needed */
}
  
.best-buy-logo-container img {
    height: 40px; /* Or whatever size you prefer */
    width: auto;
 }
  
  /* To prevent overlap with your main navbar */
.App > header {
    padding-top: 0px; /* Adjust this value to the height of your main navbar */
}

.websitelogo {
    height: 100px;
    width: auto;
}

.website-title {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .main-navbar {
        /* Adjust navbar styles for mobile */
        padding: 10px 15px; /* Example: add padding */
        height: 75px;
    }

    .main-navbar .navbar-links a {
        margin-left: 10px; /* Reduce margin between links */
        font-size: 0.9em; /* Reduce font size */
    }

    .powered-by-container {
        margin-right: 10px; /* Reduce margin on the right */
    }

    .powered-by-text {
        font-size: 0.7em; /* Reduce font size */
    }

    .best-buy-logo-container img {
        height: 30px; /* Reduce logo size */
    }

    .websitelogo {
        height: 70px; /* Reduce logo size in navbar */
    }

    .website-title {
        font-size: 0.7em; /* Reduce title font size */
        padding: 0 10px; /* Add padding to prevent text touching the edges */
    }

}