.product-item {
    margin-bottom: 10px;
    /* Add additional styling as needed */
  }
  
  .product-item img {
    max-width: 100px; /* Adjust the max-width as needed */
    height: auto; /* This will maintain the aspect ratio */
  }
  
  .product-card {
    display: flex;
    border: 1px solid #b0b0b0;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    background-color: #FEFEFE;
  }
  
  .product-image-container {
    padding: 10px;
  }
  
  .product-image {
    width: 100px; /* Fixed width, adjust as needed */
    height: auto;
    margin-left: 20px;
  }
  
  .product-info {
    padding: 10px;
    flex-grow: 1; /* Ensures it fills the remaining space */
  }
  
  .product-name {
    font-weight: bold;
    margin: 0 0 10px 0;
  }
  
  .product-price,
  .product-sale-status,
  .product-percent-savings,
  .product-dollar-savings,
  .product-regular-price {
    margin: 5px 0;
  }

  .product-price {
    font-size: 20px;
    font-weight: bold;
    color: crimson;
  }

  .product-dollar-savings {
    font-size: 20px;
    font-weight: bold;
  }
  
  .product-buy-now {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 10px;
  }

  .vendor {
    font-size: 1em;
    color: #b0b0b0;
    margin-top: 0px;
    margin-bottom: 5px;
  }