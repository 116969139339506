html, body {
  height: 100%;
  margin: 0; /* Remove default margin */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App {
  text-align: center;
  background-color: #D0D1FF; /* Your existing background color */
  flex: 1; /* This will make the App flex container fill the available space */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #2427fa;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.category-container {
  border: 0px solid #ccc;
  padding: 0px;
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.category-navbar {
  margin-top: 104px;
  position: sticky;
}

.products-scrollable {
  height: 200px; /* Adjust height as needed */
  overflow-y: auto;
}

.products-container {
  justify-content: center;
  max-width: 1000px;
  margin: auto;
  margin-top: 15px;
}

.category-button {
  display: inline-block;
  background-color: #004bed; /* Or any color you prefer */
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
  border: none; /* Remove default border */
  cursor: pointer; /* To show it's clickable */
  margin-right: 10px; /* Add space between buttons */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.category-container:hover .dropdown-content {
  display: block;
}

.dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #f1f1f1;
  border: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #ddd;
  cursor: pointer;
}

@media (max-width: 768px) {
  
  .category-navbar {
  margin-top: 75px;
  position: sticky;

  }
}